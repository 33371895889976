<template>
    <div id="playSong" class="vh-100">
        <div class="position-absolute start-50 top-50 translate-middle text-center w-100 lh-lg">
            <div class="fw-bold h3 fs-1 mb-5">준비하세요!</div>
            <div class="count text-white mb-4">{{ this.count }}</div>
            <p class="h3 lh-lg">
                노래가 곧<br>
                시작됩니다.
            </p>
        </div>
        <small class="d-flex w-100 justify-content-end align-items-end text-white position-absolute bottom-0 p-3">
            <div id="countdown" class="">
                <div id="countdown-number">
                    <svg>
                        <circle r="18" cx="20" cy="20"></circle>
                    </svg>        
                </div>
            </div>
        </small>
    </div>
</template>

<script>
let interval;
export default {
    data(){
        return{
            count: 3
        }
    },
    mounted(){
        interval = setInterval(() => {
            this.count--;
            if( this.count <= 0 ){
                this.count=3;
                clearInterval(interval);
                this.$router.push('/play');
            }
        }, 1000);

        this.$EventBus.$emit('HideHeader')
        this.$EventBus.$emit('HideNav')
    },
    destroyed(){
        this.$EventBus.$emit('ShowHeader')
        this.$EventBus.$emit('ShowNav')
        clearInterval(interval);
    }
}
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');

#playSong{
    background-image: url('../../assets/img/ready.png');
    // background-size: cover;
    background-size: auto;
    background-repeat: no-repeat;
    color: #fff;
    letter-spacing: 2px;
    animation: background-moving 30s linear forwards;
    text-shadow: 0 0 7px #fff, 0 0 10px #975035, 0 0 21px #583b18, 0 0 42px #e1ff50, 0 0 82px #ffed00, 0 0 92px #bd7100, 0 0 102px #fff700, 0 0 151px #ffcc00;
    .count{
        font-family: 'Lobster', cursive;
        font-size: 105px;
    }
    #countdown {
        position: relative;
        height: 40px;
        width: 40px;
        text-align: center;
    }

    #countdown-number {
    color: white;
    display: inline-block;
    line-height: 40px;
    }

    svg {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 40px;
    transform: rotateY(-180deg) rotateZ(-90deg);
    }

    svg circle {
    stroke-dasharray: 113px;
    stroke-dashoffset: 0px;
    stroke-linecap: round;
    stroke-width: 4px;
    stroke: rgba(255, 255, 255, 0.5);
    fill: none;
    animation: countdown 3s linear infinite forwards;
    }

    @keyframes background-moving {
        from{
            background-position: 0%;
        }
        to{
            background-position: center;
        }
    }
    @keyframes countdown {
    from {
        stroke-dashoffset: 0px;
    }
    to {
        stroke-dashoffset: 113px;
    }
    }
}
</style>